import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import logo from "./ehire.png";
import comcast from "./comcast_business.png";

import { HomePage } from "./Home";
import { CreatorPage } from "./Creator";
import { SurveyPage } from "./Survey";
import { ExportToPDFPage } from "./Export";
import { AnalyticsPage } from "./Analytics";
import { AnalyticsTabulatorPage } from "./AnalyticsTabulator";
import { AnalyticsDatatablesPage } from "./AnalyticsDatatables";

import "bootstrap/dist/css/bootstrap.css";

export default function SurveyJSReactApplication() {
  const navStyle = {
    height: '250px',
    backgroundColor: 'gray',
  };

  return (
    <Router>
      <div>
          <div className="survey-header">
            <div className="container-fluid" style={navStyle}>
                  <a className="navbar-brand" href="/">
                      <img src={logo} height="181" width="400" alt="eHire" />
                  </a>
                  <a  href="/">
                      <img src={comcast} height="181" width="324" alt="Comcast Business" style={{float: 'right', paddingTop: '30px'}}/>
                  </a>
            </div>
          </div>
        <Switch>
          <Route exact path="/">
            <SurveyPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
