export var json = 

{
 title: "Sales Fingerprint Survey for: Comcast",
 pages: [
  {
   name: "page1",
   elements: [
    {
     type: "html",
     name: "question1",
     html: "Please fill out this survey to the best of your abilities.  If you have questions or need any assistance, please contact your Sales Fingerprint support team.<p/>\nThis survey is divided into multiple sections, listed below. Each section covers a different perspective on the nature of the job opportunity.  In almost all cases, multiple answers can be chosen since sales opportunities are rarely “one size fits all”.   If a question does not seem to apply, or none of the answers are satisfactory, leave all the options unchecked.\n\n<h2>Sections</h2>\n<ul>\n<li>Sales Performance Metrics</li>\n<li>Sales Activity Metrics</li>\n<li>Buyer Personas</li>\n<li>Market Conditions</li>\n<li>Organizational Sales Culture</li>\n</ul>\n\nPlease provide some personal information in the section below before beginning the survey proper."
    },
    {
     type: "text",
     name: "question31",
     title: "What is your name?",
     isRequired: true
    },
    {
     type: "text",
     name: "question32",
     title: "What is your Comcast email address?",
     isRequired: true
    },
    {
     type: "text",
     name: "question33",
     title: "What is your job title?",
     isRequired: true
    },
    {
     type: "text",
     name: "question34",
     title: "Who is your direct manager?",
     isRequired: true
    },
    {
     type: "dropdown",
     name: "question35",
     title: "Please choose the role that is being addressed by this survey, or the role that you currently serve",
     choices: [
      {
       value: "item1",
       text: "Enterprise Account Executive (EAE)"
      },
      {
       value: "item2",
       text: "Strategic Enterprise Account (SEAE)"
      },
      {
       value: "item3",
       text: "National Account Executive (ESAE)"
      }
     ]
    }
   ],
   title: "Introduction"
  },
  {
   name: "page2",
   elements: [
    {
     type: "checkbox",
     name: "question3",
     title: "What is the typical annual new ACV (annual contract value) sales quota for this type of role (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "$250k or less"
      },
      {
       value: "item2",
       text: "$251k - $500k"
      },
      {
       value: "item3",
       text: "$501k to $750k"
      },
      {
       value: "item4",
       text: "$751k to $1m"
      },
      {
       value: "item5",
       text: "$1.01m to $1.5m"
      },
      {
       value: "item6",
       text: "$1.51m +"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question2",
     title: "What is the typical contract length of the contracts sold for this type of role (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Intro/starter (6 months)"
      },
      {
       value: "item2",
       text: "Annual (1 year)"
      },
      {
       value: "item3",
       text: "Two year (2 years)"
      },
      {
       value: "item4",
       text: "Three year (3 years)"
      },
      {
       value: "item5",
       text: "Three Plus (3-5 years)"
      },
      {
       value: "item6",
       text: "Long Term (5 year plus)"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question4",
     title: "What is the average MRR (Monthly Recurring Revenue) sale for typical contracts won for this role (check all that apply)??",
     choices: [
      {
       value: "item1",
       text: "$200 or less"
      },
      {
       value: "item2",
       text: "$201 to $500"
      },
      {
       value: "item3",
       text: "$501 to $750"
      },
      {
       value: "item4",
       text: "$751 to $1,000"
      },
      {
       value: "item5",
       text: "$1,001 to $1,500"
      },
      {
       value: "item6",
       text: "$1,501 to $2,500"
      },
      {
       value: "item7",
       text: "$2,501 to $4,000"
      },
      {
       value: "item8",
       text: "$4,001 to $6,000"
      },
      {
       value: "item9",
       text: "$6,001 to $10,000"
      },
      {
       value: "item10",
       text: "$10,001+"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question4.1",
     title: "What is the average MRR (Monthly Recurring Revenue) sales quota for this role (check all that apply)??",
     choices: [
      {
       value: "item4",
       text: "under $1,000"
      },
      {
       value: "item5",
       text: "$1,001 to $1,500"
      },
      {
       value: "item6",
       text: "$1,501 to $2,500"
      },
      {
       value: "item7",
       text: "$2,501 to $4,000"
      },
      {
       value: "item8",
       text: "$4,001 to $6,000"
      },
      {
       value: "item9",
       text: "$6,001 to $8,500"
      },
      {
       value: "item10",
       text: "$8,501 to $10,000"
      },
      {
       value: "item11",
       text: "$10,001+"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question5",
     title: "What is the typical sales cycle (from opportunity identification to close) for this role?",
     choices: [
      {
       value: "item1",
       text: "Very short ( 1 to 3 weeks)"
      },
      {
       value: "item2",
       text: "Short ( 1 month to 6 weeks)"
      },
      {
       value: "item3",
       text: "Modest ( 6 weeks to 3 months)"
      },
      {
       value: "item4",
       text: "Medium ( 3 to 6 months)"
      },
      {
       value: "item5",
       text: "Medium to Long ( 6 months to a year)"
      },
      {
       value: "item6",
       text: "Very Long ( 1 to 2 years)"
      },
      {
       value: "item7",
       text: "Strategic Pursuit Length ( 2 years or longer)"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question6",
     title: "How many contracts/transactions are typically closed monthly by an average quota-attaining performer in this role (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "1 - 3"
      },
      {
       value: "item2",
       text: "4 - 5"
      },
      {
       value: "item3",
       text: "6 - 8"
      },
      {
       value: "item4",
       text: "9 - 12"
      },
      {
       value: "item5",
       text: "12+"
      }
     ]
    }
   ],
   title: "Sales Performance Metrics"
  },
  {
   name: "page3",
   elements: [
    {
     type: "checkbox",
     name: "question6b",
     title: "How many Initial Appointments Held (IAH) are expected to be completed each month as prescribed by leadership (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "1-2"
      },
      {
       value: "item2",
       text: "3-5"
      },
      {
       value: "item3",
       text: "6-10"
      },
      {
       value: "item4",
       text: "11-20"
      },
      {
       value: "item5",
       text: "21-30"
      },
      {
       value: "item6",
       text: "31+"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question7",
     title: "How many current sales activities (touches/calls/visits) are expected to be attempted and documented each week as mandated by leadership (check all that apply)?",
     choices: [
      {
       value: "item3",
       text: "under 10"
      },
      {
       value: "item4",
       text: "10 - 20"
      },
      {
       value: "item5",
       text: "21 - 50"
      },
      {
       value: "item6",
       text: "51 - 100"
      },
      {
       value: "item7",
       text: "101 - 150"
      },
      {
       value: "item8",
       text: "151 - 200"
      },
      {
       value: "item9",
       text: "Not mandated."
      }
     ]
    },
    {
     type: "checkbox",
     name: "question8",
     title: "How much opportunity value needs to be in the pipeline/funnel in order to expect to meet quota for this role (check all that apply)?",
     choices: [
      {
       value: "item2",
       text: "1x"
      },
      {
       value: "item3",
       text: "2x"
      },
      {
       value: "item4",
       text: "3x - 5x"
      },
      {
       value: "item5",
       text: "6x - 10x"
      },
      {
       value: "item6",
       text: "11x - 20x"
      },
      {
       value: "item7",
       text: "21x +"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question9",
     title: "How much time is spent planning, coordinating and preparing for upcoming sales activities each week (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "less than 1 hour"
      },
      {
       value: "item2",
       text: "1 - 2 hours"
      },
      {
       value: "item3",
       text: "2 - 3 hours"
      },
      {
       value: "item4",
       text: "3 - 5 hours"
      },
      {
       value: "item5",
       text: "5 - 10 hours"
      },
      {
       value: "item6",
       text: "more than 10 hours"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question10",
     title: "What percentage of the role is spent hunting new business vs selling/supporting existing accounts (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Less than 20%"
      },
      {
       value: "item4",
       text: "21% - 40%"
      },
      {
       value: "item6",
       text: "41% - 60%"
      },
      {
       value: "item8",
       text: "61% - 80%"
      },
      {
       value: "item10",
       text: "80% +"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question11",
     title: "What percentage of the role is spent selling internally/handling internal processes vs. calling on clients/targets (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Less than 20%"
      },
      {
       value: "item4",
       text: "21% - 40%"
      },
      {
       value: "item6",
       text: "41% - 60%"
      },
      {
       value: "item8",
       text: "61% - 80%"
      },
      {
       value: "item10",
       text: "80% +"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question12",
     title: "What percentage of the role is spent documenting activities in CRM in a typical 50-hour work week (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Less than 20%"
      },
      {
       value: "item4",
       text: "21% - 40%"
      },
      {
       value: "item6",
       text: "41% - 60%"
      },
      {
       value: "item8",
       text: "61% - 80%"
      },
      {
       value: "item10",
       text: "80% +"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question13",
     title: "How closely is the salesperson expected to adhere to company-prescribed sales methodologies and activities (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "The salesperson is free to sell however they prefer as long as the job gets done, i.e. there is no standard as prescribed by the manager"
      },
      {
       value: "item2",
       text: "The salesperson should follow core guidelines of the corporate sales methodology but its not mandated or managed by the company"
      },
      {
       value: "item3",
       text: "The salesperson is expected to follow most of the guidelines of the corporate sales methodology and activities, but this is not closely managed or inspected"
      },
      {
       value: "item4",
       text: "The salesperson is expected to follow all the corporate methodologies and prescribed activities and this requirement is inspected and managed to regularly"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question14",
     title: "How challenging is it for the average salesperson in this role to meet monthly quota consistently? Alternatively, how often do the existing salespeople in this role achieve their monthly quota goals (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Attainable, 75% (or more) likely to hit quota"
      },
      {
       value: "item2",
       text: "Average, 50% (or more) likely to hit quota"
      },
      {
       value: "item3",
       text: "Difficult, 25% (or more) likely to hit quota"
      },
      {
       value: "item4",
       text: "Very challenging, 10% (or more) likely to hit quota"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question15",
     title: "How much time per month is expected to be spent investing in community-related activities ( tech associations, professional networking groups, business association activities, etc.) in the market served (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Not expected or mandated"
      },
      {
       value: "item2",
       text: "Moderate investment ( 1 - 5 hours)"
      },
      {
       value: "item3",
       text: "Medium investment ( 6 - 10 hours)"
      },
      {
       value: "item4",
       text: "Substantial Investment ( 11 - 20 hours)"
      },
      {
       value: "item5",
       text: "Significant Investment ( 20 hours+)"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question16",
     title: "How much time do you feel is important to be spent in community related activities in the market each month in order to achieve consistent sales success (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Not important or impactful"
      },
      {
       value: "item2",
       text: "Moderate investment ( 1 - 5 hours)"
      },
      {
       value: "item3",
       text: "Medium investment ( 6 - 10 hours)"
      },
      {
       value: "item4",
       text: "Substantial Investment ( 11 - 20 hours)"
      },
      {
       value: "item5",
       text: "Significant Investment ( 20 hours+)"
      }
     ]
    }
   ],
   title: "Sales Activity Metrics"
  },
  {
   name: "page4",
   elements: [
    {
     type: "checkbox",
     name: "question17",
     title: "Who should salespeople in this role typically target as a first point of contact on a new prospective client (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Internal Influencer"
      },
      {
       value: "item2",
       text: "Manager / Senior Manager"
      },
      {
       value: "item3",
       text: "Director Level"
      },
      {
       value: "item4",
       text: "VP - SVP"
      },
      {
       value: "item5",
       text: "C-Suite or equivalent"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question18",
     title: "What is the typical size/scale of an average customer within this role (check all that apply)?",
     choices: [
      {
       value: "item2",
       text: "Small  (25 - 100 employees)"
      },
      {
       value: "item4",
       text: "Medium (100 - 500 employees)"
      },
      {
       value: "item7",
       text: "Large (500 - 1,000 employees)"
      },
      {
       value: "item9",
       text: "Large enterprise (1,000 - 5,000 employees)"
      },
      {
       value: "item10",
       text: "Fortune 500 or similar scale"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question19",
     title: "In which functional area is the primary decision maker typically found (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "IT / Technical Operations"
      },
      {
       value: "item2",
       text: "Operations / Office Management"
      },
      {
       value: "item3",
       text: "Finance"
      },
      {
       value: "item4",
       text: "Executive Leadership"
      },
      {
       value: "item5",
       text: "Human Resources"
      },
      {
       value: "item6",
       text: "Marketing"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question20",
     title: "How price-sensitive is the typical customer, based on current customers (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Completely focused on value/quality"
      },
      {
       value: "item2",
       text: "Mostly focused on value/quality over price"
      },
      {
       value: "item3",
       text: "A balanced focus of value/quality and price"
      },
      {
       value: "item4",
       text: "Mostly focused on price"
      },
      {
       value: "item5",
       text: "Exclusively focused on price"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question21",
     title: "How strategic are your products to your client’s business strategy (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Highly tactical and commoditized, very low impact of change on internal or external company stakeholders"
      },
      {
       value: "item2",
       text: "Is meaningful factor to overall business success, change will make a modest impact on stakeholders"
      },
      {
       value: "item3",
       text: "Provides a significant impact and is a critical factor for success of client and key stakeholders"
      },
      {
       value: "item4",
       text: "Critical to overall business success and provides key competitive advantage or capabilities to client"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question21b",
     title: "What percentage of new client wins are assisted, supported or related to marketing programs (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Less than 20%"
      },
      {
       value: "item4",
       text: "21% - 40%"
      },
      {
       value: "item6",
       text: "41% - 60%"
      },
      {
       value: "item8",
       text: "61% - 80%"
      },
      {
       value: "item10",
       text: "80% +"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question22",
     title: "How critical is Product and Industry knowledge for success in this role?",
     choices: [
      {
       value: "item1",
       text: "Minimal"
      },
      {
       value: "item2",
       text: "Moderate"
      },
      {
       value: "item3",
       text: "Important"
      },
      {
       value: "item5",
       text: "Critical"
      }
     ]
    },
    {
     type: "ranking",
     name: "question36",
     title: "There are three key competencies of a salesperson: Sales craft/art, Sales activity/discipline, and Product/Subject Matter expertise. Rank these in order of importance for this role.",
     choices: [
      "Sales Art/Craft",
      "Sales Activity/Discipline",
      "Product/Subject Matter expertise"
     ]
    },
    {
     type: "checkbox",
     name: "question23",
     title: "How many customer stakeholders are involved and have to agree before your typical customer for this role commits to a deal (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "1"
      },
      {
       value: "item2",
       text: "2-4"
      },
      {
       value: "item3",
       text: "5-10"
      },
      {
       value: "item4",
       text: "11-25"
      },
      {
       value: "item5",
       text: "More than 25"
      }
     ]
    }
   ],
   title: "Buyer Personas"
  },
  {
   name: "page5",
   elements: [
    {
     type: "checkbox",
     name: "question24",
     title: "Do your customers view the products sold by people in this role to be a commodity (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Very much considered a commodity (lots of competitors offering the same product(s))"
      },
      {
       value: "item2",
       text: "Mostly considered a commodity, some unique value provided by the products"
      },
      {
       value: "item3",
       text: "A balanced mixture of commodity and perceived product value (few quality competitors)"
      },
      {
       value: "item4",
       text: "Mostly considered as highly valuable and unique ( key capabilities, solutioning and company differentiation is key)"
      },
      {
       value: "item5",
       text: "Almost completely unique by vendor solution (each competitor brings unique solutions and products to table to demonstrate value)"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question25",
     title: "How many competitively viable options are easily available/accessible to your prospective customers when considering a solution (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "1 - 5 competitive options"
      },
      {
       value: "item2",
       text: "6 - 10 competitive options"
      },
      {
       value: "item3",
       text: "11 - 15 competitive options"
      },
      {
       value: "item4",
       text: "16+ competitive options"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question25b",
     title: "How often does your organization win in head-to-head sales contests from your POV? i.e. how competitive are your solutions and price versus the competition's on a like-for-like basis (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Less than 20%"
      },
      {
       value: "item4",
       text: "21% - 40%"
      },
      {
       value: "item6",
       text: "41% - 60%"
      },
      {
       value: "item8",
       text: "61% - 80%"
      },
      {
       value: "item10",
       text: "80% +"
      }
     ]
    }
   ],
   title: "Market Conditions"
  },
  {
   name: "page6",
   elements: [
    {
     type: "checkbox",
     name: "question26",
     title: "How often is the salesperson’s PIPELINE inspected by leadership/management in this role? (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "More than once each day"
      },
      {
       value: "item2",
       text: "At least once a day"
      },
      {
       value: "item3",
       text: "Three times a week"
      },
      {
       value: "item4",
       text: "At least once per week"
      },
      {
       value: "item5",
       text: "Bi-monthly"
      },
      {
       value: "item6",
       text: "Monthly"
      },
      {
       value: "item7",
       text: "Quarterly"
      },
      {
       value: "item8",
       text: "Rarely, if ever"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question27",
     title: "How often is the salesperson’s ACTIVITY inspected by leadership/management in this role (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "More than once each day"
      },
      {
       value: "item2",
       text: "At least once a day"
      },
      {
       value: "item3",
       text: "Three times a week"
      },
      {
       value: "item4",
       text: "At least once per week"
      },
      {
       value: "item5",
       text: "Bi-monthly"
      },
      {
       value: "item6",
       text: "Monthly"
      },
      {
       value: "item7",
       text: "Quarterly"
      },
      {
       value: "item8",
       text: "Rarely, if ever"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question29",
     title: "How much of the training has been focused on product knowledge/internal processes vs the art/science of selling (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Almost exclusively focused on the products and/or internal processes"
      },
      {
       value: "item2",
       text: "Mostly focused on the products and internal processes"
      },
      {
       value: "item4",
       text: "A balanced focus on the products/internal processes and the art/science of selling"
      },
      {
       value: "item5",
       text: "Mostly focused on the art/science of selling"
      },
      {
       value: "item6",
       text: "Almost exclusively focused on the art/science of selling"
      }
     ]
    },
    {
     type: "checkbox",
     name: "question28",
     title: "How often does your organization share ideas/stories/best practices from other regions or sales teams ( via call, conference, shared team training, internal posts, newsletter, etc. ) (check all that apply)?",
     choices: [
      {
       value: "item1",
       text: "Often (weekly/bi-weekly)"
      },
      {
       value: "item2",
       text: "Frequently ( once a month)"
      },
      {
       value: "item4",
       text: "Common but not frequent (quarterly)"
      },
      {
       value: "item5",
       text: "Infrequent but consistent ( semi-annually or annually)"
      },
      {
       value: "item6",
       text: "Rarely, if ever"
      }
     ]
    },
    {
     type: "ranking",
     name: "question30",
     title: "Which of these most closely describe what your sales culture rewards and values, in order of importance",
     choices: [
      "Individual/Team Competition",
      "Results & Pipeline Development",
      "Collaboration, Teamwork and Sharing",
      "Process Activities and Metrics",
      "Dealmaking / Sales Art",
      "Ethics and Stewardship",
      "Hunting and winning new business",
      "Expanding business with existing customers"
     ]
    }
   ],
   title: "Organizational Sales Culture"
  }
 ],
 cookieName: "ehire-sfa-survey-comcast",
 showProgressBar: "top"
}
